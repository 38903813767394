/** @jsx jsx */
import { jsx, Link as TLink } from "theme-ui"
import * as React from "react"
import { Link } from "gatsby"
import { readableColor } from "polished"
import useJodieConfig from "../hooks/use-jodie-config"
import { ImInstagram } from 'react-icons/im'
import { useBreakpointIndex } from '@theme-ui/match-media'

const Navigation = ({ bg }: { bg: string }) => {
  const { navigation } = useJodieConfig()
  const index = useBreakpointIndex()
  return (
    <nav
      aria-label="Primary Navigation"
      sx={{
        a: {
          color: readableColor(bg),
          textDecoration: `none`,
          fontSize: [1, 2, 2, 3],
          marginLeft: ['0.6rem', 3, 3, 0],
          "&:hover,&:focus": {
            color: readableColor(bg, `primary`, `primaryLight`, false),
          },
        },
        ul: {
          textAlign: ['right', 'left'],
          margin: 0,
          padding: 0,
          li: {
            listStyle: `none`,
            display: [`inline-block`, `inline-block`, `inline-block`, `block`],
          },
        },
        variant: `navigation`,
      }}
    >
      <ul>
        {navigation.map((navItem) => {
          return (<li key={navItem.slug}>
            <TLink as={Link} to={navItem.slug} sx={navItem.name === 'Instagram' ? { svg: { marginBottom: ['-2px', 'unset'] } } : {}}>
              {navItem.name === 'Instagram' && index === 0 ? <ImInstagram /> : navItem.name}
            </TLink>
          </li>)
        })}
      </ul>
    </nav >
  )
}

export default Navigation
