import * as React from "react";
const Logo = () => (
  <svg viewBox="0 0 1226 1324" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>KlikPhotography</title>

    <g id="DovilesKlik" stroke-width="1">
      <g
        id="Group-2"
        transform="translate(614.000000, 625.500000) rotate(-90.000000) translate(-614.000000, -625.500000) translate(-156.500000, -26.500000)"
      >
        <g
          id="Group"
          transform="translate(770.500000, 654.000000) rotate(90.000000) translate(-770.500000, -654.000000) translate(158.500000, -116.500000)"
        >
          <text
            id="Photography"
            font-family="AvenirNext-Regular, Avenir Next"
            font-size="170"
            font-weight="normal"
            line-spacing="129"
            letter-spacing="18.1675183"
          >
            <tspan x="6" y="1365">
              Photography
            </tspan>
          </text>
          <g
            id="Logo2"
            transform="translate(0.000000, 149.000000)"
            stroke=""
            stroke-linecap="square"
          >
            <g
              id="Bottom-Right"
              transform="translate(1138.000000, 898.000000) rotate(-180.000000) translate(-1138.000000, -898.000000) translate(1058.000000, 818.000000)"
              stroke-width="8"
            >
              <line
                x1="0.5"
                y1="0.526315789"
                x2="0.5"
                y2="159.473684"
                id="Line"
              ></line>
              <line x1="1" y1="0.5" x2="160" y2="0.5" id="Line"></line>
            </g>
            <g id="Top-Left-Corner" stroke-width="8">
              <line
                x1="0.5"
                y1="0.526315789"
                x2="0.5"
                y2="159.473684"
                id="Line"
              ></line>
              <line x1="1" y1="0.5" x2="160" y2="0.5" id="Line"></line>
            </g>
            <g
              id="Top-Right-Corner"
              transform="translate(1138.000000, 80.000000) scale(-1, 1) translate(-1138.000000, -80.000000) translate(1058.000000, 0.000000)"
              stroke-width="8"
            >
              <line
                x1="0.5"
                y1="0.526315789"
                x2="0.5"
                y2="159.473684"
                id="Line"
              ></line>
              <line x1="1" y1="0.5" x2="160" y2="0.5" id="Line"></line>
            </g>
            <g
              id="Center"
              transform="translate(208.000000, 194.000000)"
              stroke-width="20"
            >
              <g id="LeftSide" transform="translate(0.555556, 0.000000)">
                <line x1="0" y1="0" x2="266.666667" y2="279" id="Line-2"></line>
                <line
                  x1="0"
                  y1="279"
                  x2="266.666667"
                  y2="558"
                  id="Line-2"
                  transform="translate(133.333333, 418.500000) scale(1, -1) translate(-133.333333, -418.500000) "
                ></line>
                <line
                  x1="271.444444"
                  y1="0.58490566"
                  x2="271.444444"
                  y2="557.415094"
                  id="Line-3"
                ></line>
                <line
                  x1="271.450687"
                  y1="558.393478"
                  x2="419.444444"
                  y2="558"
                  id="Line-4"
                ></line>
              </g>
              <g id="RightSide" transform="translate(419.555556, 1.000000)">
                <line
                  x1="136.222222"
                  y1="0"
                  x2="402.888889"
                  y2="279"
                  id="Line-2"
                  transform="translate(269.555556, 139.500000) scale(-1, 1) translate(-269.555556, -139.500000) "
                ></line>
                <line
                  x1="136.222222"
                  y1="279"
                  x2="402.888889"
                  y2="558"
                  id="Line-2"
                  transform="translate(269.555556, 418.500000) scale(-1, -1) translate(-269.555556, -418.500000) "
                ></line>
                <line
                  x1="131.444444"
                  y1="0.58490566"
                  x2="131.444444"
                  y2="557.415094"
                  id="Line-3"
                  transform="translate(131.444444, 279.000000) scale(-1, 1) translate(-131.444444, -279.000000) "
                ></line>
                <line
                  x1="0.561797753"
                  y1="557.393478"
                  x2="131.555556"
                  y2="557"
                  id="Line-4"
                  transform="translate(66.000000, 556.786957) scale(-1, 1) translate(-66.000000, -556.786957) "
                ></line>
              </g>
            </g>
            <g
              id="Bottom-Left"
              transform="translate(80.000000, 898.000000) scale(-1, 1) rotate(-180.000000) translate(-80.000000, -898.000000) translate(-0.000000, 818.000000)"
              stroke-width="8"
            >
              <line
                x1="0.5"
                y1="0.526315789"
                x2="0.5"
                y2="159.473684"
                id="Line"
              ></line>
              <line x1="1" y1="0.5" x2="160" y2="0.5" id="Line"></line>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Logo;
